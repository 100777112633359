@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-white text-gray-700; /* Example Tailwind classes */
}

code {
  @apply font-mono text-sm; /* Example Tailwind classes for 'code' elements */
}
